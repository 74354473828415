<!-- 党风廉政 -->
<template>
  <div class="wrap">
<!--    <base-banner id="20"></base-banner>-->
    <!-- 举报列表 -->
    <ul class="list">
      <li v-for="a in list" :key="a.id" @click="goRoute(a)" class="flex-between">
        <div class="itemInfo">
          <table class="table">
            <tr>
              <th>标题</th>
              <th>回复</th>
              <th>日期</th>
            </tr>
            <tr>
              <td>{{a.title}}</td>
              <td>{{a.reply}}</td>
              <td>{{a.date}}</td>
            </tr>
          </table>
        </div>
      </li>
    </ul>

    <!-- 分页 -->
    <div class="pagination flex-center" v-if="total>10">
      <el-pagination
          background
          layout="prev, pager, next"
          @current-change="getPageNum"
          :total="total"
      ></el-pagination>
    </div>
    <!-- 举报要求 -->
    <base-detail :content="detail"></base-detail>

    <!-- 表单 -->
      <div class="complaint-form">
        <h2>添加投诉信息</h2>
        <form ref="form" label-width="80px" :model="form"  @submit.prevent="onSubmit">
          <div>
            <label for="cate">投诉类型:</label>
            <select id="cate" class="custom-select" v-model="form.cate" required>
              <option v-for="option in options" :key="option.value" :value="option.value">
                {{ option.text }}
              </option>
            </select>
          </div>

          <div>
            <label for="title">投诉主题:</label>
            <input type="text" v-model="form.title" id="title" required />
          </div>

          <div>
            <label for="content">投诉内容:</label>
            <textarea v-model="form.content" id="content" rows="4" required></textarea>
          </div>

          <div>
            <label for="name">联系人姓名:</label>
            <input type="text" v-model="form.name" id="name" required />
          </div>

          <div>
            <label for="name">身份证号(选填):</label>
            <input type="text" v-model="form.card" id="card" />
          </div>

          <div>
            <label for="name">联系人手机号:</label>
            <input type="text" v-model="form.phone" id="phone" required />
          </div>

          <div>
            <label for="email">邮箱(选填):</label>
            <input type="email" v-model="form.email" id="email" />
          </div>

          <div>
            <label for="email">工作单位(选填):</label>
            <input type="text" v-model="form.work" id="work" />
          </div>

          <button type="submit">提交</button>
        </form>
      </div>

  </div>
</template>

<script>
import {HTTP_jubaoIndex, HTTP_jubaoSave, HTTP_jubaoDetail} from "../../../http/modules/ddjs";
import {baseURL} from "@/config";
export default {
  data() {
    return {
      detail: {},
      list: [],
      page: 0,
      size: 10,
      total: 0,
      form: { // 投诉表单数据
        title: '',
        content: '',
        name: '',
        phone: '',
        email: '',
        card: '',
        work: '',
        cate: ''
      },
      selectedOption: '',
      options: [
        { text: '建议', value: '1' },
        { text: '表扬', value: '2' },
        { text: '咨询', value: '3' },
        { text: '投诉', value: '4' },
      ],
      message: ''
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    goRoute(a) {
      if (a.url) {
        window.open(a.url);
      } else {
        this.$router.push({
          name: "jubaoDetail",
          query: { id: a.id },
        });
      }
    },
    getList() {
      let { page, size } = this;
      this.$api.HTTP_jubaoIndex(page, size).then((res) => {
        this.detail = res.content;
        this.list = res.list;
        this.total = res.total;
      });
    },
    getPageNum(e) {
      this.page = e-1;
      this.getList();
    },
    /* 提交 */
    onSubmit() {
      this.$api.HTTP_jubaoSave(this.form).then((res) => {
        //返回res是data
        this.$message.success("提交成功，请等待工作人员处置！");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap{
  padding-bottom: 20px;
}
.custom-select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}
.complaint-form {
  background: #fff;
  padding: 0 20px;
  margin: 16px auto;
  max-width: 1200px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.complaint-form h2 {
  text-align: center;
}

.complaint-form div {
  margin-bottom: 15px;
}

.complaint-form label {
  display: block;
  margin-bottom: 5px;
}

.complaint-form input,
.complaint-form textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.complaint-form button {
  width: 100%;
  padding: 10px;
  background-color: #015571;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.complaint-form button:hover {
  background-color: #015571;
}
.table {
  width: 100%;
  border-collapse: collapse; /* 合并表格边框 */
  margin: 20px 0; /* 表格上下外边距 */
  font-size: 18px; /* 字体大小 */
  text-align: left; /* 文字左对齐 */
}
.table th, .table td {
  padding: 12px; /* 内边距 */
  border: 1px solid #ddd; /* 边框样式 */
}
.table th {
  background-color: #f2f2f2; /* 表头背景颜色 */
  color: black; /* 表头文字颜色 */
}
.table tr:nth-child(even) {
  background-color: #f9f9f9; /* 偶数行背景颜色 */
}
.table tr:hover {
  background-color: #f1f1f1; /* 鼠标悬停背景颜色 */
}
.list {
  background-color: #F5F5F5;
  padding: 0 20px;
  width: 1200px;
  margin: 16px auto;
  li {
    padding: 55px 0 15px;
    border-bottom: 1px solid #ebebeb;
    cursor: pointer;
    .itemInfo {
      width: 990px;
      height: 122px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .title {
        font-size: 18px;
        color: #000;
      }
      .des {
        font-size: 18px;
        color: #000;
      }
      .date {
        font-size: 16px;
        color: #333;
      }

    }
  }
}
</style>
