<!-- v-header 组件 -->
<template>
  <div class="flex-between navLogoBox">
    <div class="logo flex-start">
      <img class="logo1" src="~images/logo1.png" alt />
      <img class="logo2" src="~images/logoText.png" alt />
    </div>
    <div class="flex-start">
      <ul class="list flex-start">
        <router-link tag="li" :to="{name:'bkzx'}">招生简章</router-link>
        <router-link tag="li" :to="{name:'wsbm'}">网上报名</router-link>
        <router-link tag="li" :to="{name:'jubao'}">投诉举报</router-link>
        <li>
          <img class="search" @click="gosearch" src="~images/icon10.png" alt />
        </li>
      </ul>
      <el-dropdown @command="logOut">
        <div @click="login" class="login">
          <img v-if="!userInfo.avatar" src="~images/my.png" alt />
          <img v-if="userInfo.avatar" :src="userInfo.avatar" alt />
        </div>
        <el-dropdown-menu v-if="userInfo.avatar" slot="dropdown">
          <el-dropdown-item command="logOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { Cookie, Session } from "@/utils/storage";
export default {
  data() {
    return {
      userInfo: {},
    };
  },

  mounted() {
    if (Cookie.get("js_api_token")) {
      this.$api.HTTP_UserInfo().then((res) => {
        this.userInfo = res;
      });
    }
  },

  methods: {
    login() {
      let path = window.location.href;
      if (!Session.get("js_api_token")) {
        window.location.href = "https://xzjsxy.net/webwx?url=" + path;
      }
    },
    logOut() {
      this.$confirm("确认退出当前账号吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        Cookie.remove("js_api_token");
        Session.remove("js_api_token");
        this.userInfo = {};
        window.location.href = "https://xzjsxy.net/";
        this.$message({
          type: "success",
          message: "退出成功!",
        });
      });
    },
    gosearch() {
      this.$router.push({
        name: "search",
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.navLogoBox {
  width: 1200px;
  height: 150px;
  margin: 0 auto;
  padding: 0 20px;
  // background: url(~images/topbackground.png) no-repeat center/100% 100%;
  .logo {
    .logo1 {
      width: 108px;
    }
  }
  .login {
    cursor: pointer;
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }
}
.list {
  font-size: 18px;
  color: #fff;
  border-right: 1px solid #fff;
  margin-right: 20px;
  li {
    width: 98px;
    white-space: nowrap;
    cursor: pointer;
  }
  .search {
    width: 32px;
  }
}
</style>