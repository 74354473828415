<!-- 举报详情 -->
<template>
  <div class="wrap">
    <!-- banner -->
    <base-banner id="19"></base-banner>
    <base-detail
        :title="detail.title"
        :date="detail.date"
        :content="detail.content"
    ></base-detail>
    <div class="status">处理状态：{{detail.status}}</div>
    <div  class="status">处理结果：{{detail.reply}}</div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      detail: {},
    };
  },
  watch: {
    //监听路由
    "$route.query.id": function () {
      this.getInfoFn();
    },
  },
  mounted() {
    this.getInfoFn();
  },
  methods: {
    getInfoFn() {
      this.$api.HTTP_jubaoDetail(this.$route.query.id).then((res) => {
        this.detail = res;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.wrap{
  padding-bottom: 20px;
}
.status{
  padding: 0 20px;
  margin: 16px auto;
  width: 1200px;
}
</style>
